import Project from "../components/Project.jsx";
import { ProjectLinks, ProjectLink } from "../components/ProjectLinks.jsx";
import ReactPlayer from "react-player";
import Typography from "@mui/material/Typography";

export default function Main() {
  return (
    <Project
      title="SpectrumX"
      picture={
        <img
          alt="certificate"
          className="project-img"
          src={require("../pictures/spectrumx.jpeg")}
          width={400}
        />
      }
      text={
        <Typography align="justify" sx={styles.text}>
          Learned about the spectrum of frequencies that the internet uses. Set
          up a device that records IQ data, using an antenna, a low cost SDR,
          and an android phone with the RF Analyzer app. Converted the IQ data
          to spectrogram format. Developed algorithms to remove noise in Python,
          using Numpy, and measured the accuracy with the Jaccard index.
        </Typography>
      }
      links={
        <ProjectLinks>
          <ProjectLink link="https://www.spectrumx.org">SpectrumX</ProjectLink>
          <ProjectLink link="/spectrumx-slide">Presentation</ProjectLink>
        </ProjectLinks>
      }
    />
  );
}

const styles = {
  text: {
    width: 0.9,
    fontSize: 20,
  },
};
