import links from "../links.js";
import { Link } from "react-router-dom";
import { useState } from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export default function Home() {
  const [type, set_type] = useState("all");

  return (
    <Stack sx={styles.container}>
      <Stack spacing={4} direction="row" sx={styles.top}>
        {types.map((x) => (
          <Link key={x} className="link" onClick={() => set_type(x)}>
            <Typography
              sx={{
                textDecoration: type === x && "underline",
                ...styles.left_link,
              }}
              key={x}
            >
              {x}
            </Typography>
          </Link>
        ))}
      </Stack>

      <Stack sx={styles.content} spacing={2} className="nav">
        {links.map((x) => {
          if (type === "all" || type === x.type)
            return (
              <ProjectLink
                key={x.link}
                link={x.link}
                type={x.type}
                title={x.title}
                subtitle={x.subtitle}
              />
            );
          return null;
        })}
      </Stack>
    </Stack>
  );
}

function ProjectLink({ link, title, type, subtitle }) {
  const [hover, set_hover] = useState(false);
  return (
    <Link
      onMouseEnter={() => set_hover(true)}
      onMouseLeave={() => set_hover(false)}
      key={link}
      className="link"
      to={`/${type}/${link}`}
    >
      <Stack alignItems="end" direction="row" spacing={2}>
        <Typography sx={{ fontWeight: hover && "bold", fontSize: 18, pb: 1.6 }}>
          {subtitle}
        </Typography>
        <Typography sx={{ fontWeight: hover && "bold", fontSize: 50 }}>
          {title}
        </Typography>
      </Stack>
    </Link>
  );
}
const types = ["web", "misc", "hack", "all"];

const styles = {
  container: { width: 1, height: 1 },

  top: {
    width: 1,
    height: "30px",
    justifyContent: "center",
  },

  content: {
    width: 1,
    height: "calc( 100% - 30px)",
    justifyContent: "center",
    alignItems: "flex-end",
  },

  left_link: {
    fontSize: 20,
    "&:hover": { fontWeight: "bold" },
  },
};
